import React from 'react';
import ReactDOM from 'react-dom';
import {IntlProvider} from "react-intl";
import { Route, Router, Switch } from 'react-router-dom';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl'
import { Provider } from 'react-redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import notificationsMiddleware from 'middlewares/notifications';
import reducers from 'reducers';
import sagas from 'sagas';
import localeData from 'i18n/index';
import i18n from 'utils/i18n';
import { history } from 'utils/history';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ScrollToTop } from 'utils/scroll';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from 'keycloak.js'
import OnLoadingComponent from 'components/OnLoadingComponent'
import KeycloakLoginButton from 'components/KeycloakLoginButton'



import { 
  HOME_PATH
} from 'constants.js';

import './index.module.scss';

export const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  combineReducers(reducers),
  composeWithDevTools({})(
    applyMiddleware(sagaMiddleware, notificationsMiddleware)
  )
);

// Run sagas
sagaMiddleware.run(sagas);

// Optional, but prevents memory leaks
const cache = createIntlCache();

const handleOnEvent = async (event, error) => {
  if (event === 'onAuthSuccess') {
    if (keycloak.authenticated) {
      localStorage.setItem('token', keycloak.token)
    }
  }
}


const intl = createIntl({
  locale: 'en',
  messages: i18n(localeData)
}, cache);

ReactDOM.render(
  <IntlProvider locale='en'>
    <Provider store={store}>
      <RawIntlProvider value={intl}>
      <ReactKeycloakProvider
          authClient={keycloak}
          initOptions={{ pkceMethod: 'S256' }}
          LoadingComponent={<OnLoadingComponent/>}
          onEvent={handleOnEvent}
        >
          <Router history={history}>
            <ScrollToTop>
              <Switch>
                <Route path={HOME_PATH} component={App}/>
              </Switch>
            </ScrollToTop>
          </Router>
        </ReactKeycloakProvider>
      </RawIntlProvider>
    </Provider>
  </IntlProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
