import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import StylesProvider from "@material-ui/styles/StylesProvider";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from 'keycloak-js'

//import Containers
import Notifications from "containers/Notifications";
import AuthHandler from "containers/AuthHandler";

//import pages
import Layout from 'components/Layout'
import UserProfile from 'pages/Userprofile'
import Charger from 'containers/Charger'
import Home from 'containers/Home'
import Tutorial from 'pages/Tutorial'
import InvoicePage from 'pages/InvoicePage'
import KeycloakLoginButton from 'components/KeycloakLoginButton'

 //import components
import InstallPrompt from 'components/InstallPrompt';
import OnLoadingComponent from 'components/OnLoadingComponent'

//import constants
import {
  PROFILE_PATH,
  CHARGER_PATH,
  HOME_PATH,
  TUTORIAL_PATH,
  INVOICE_PATH,
  API_ATUH_KC,
  API_AUTH
} from "constants.js";

let deferredPrompt;

const App = () => {

  const [installable, setInstallable] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      deferredPrompt = event;
      if(localStorage.getItem('installable') !== 'false'){
        setInstallable(true)
      }
    });

      window.addEventListener('appinstalled', () => {
      console.log('INSTALL: Success');
    });
  }, []);

  const handleInstall = () => {
    console.log("APP INSTALL")
    setInstallable(false);
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  }

  return (
  <StylesProvider injectFirst>
    <Notifications />
    {localStorage.getItem('loggedIn') === "true" && localStorage.getItem('token')
      ?<AuthHandler>
        <Layout>
          <Switch className="content">
              <Route path={HOME_PATH} exact component={Home} />
              <Route path={PROFILE_PATH} exact component={UserProfile} />
              <Route path={CHARGER_PATH} exact component={Charger} />
              <Route path={TUTORIAL_PATH} exact component={Tutorial} />
              <Route path={INVOICE_PATH} exact component={InvoicePage} />
              <Route component={Home}/> 
          </Switch>
        </Layout>
      </AuthHandler>
      :<KeycloakLoginButton/>}
    {installable && <InstallPrompt handleInstall={handleInstall} />}
  </StylesProvider>
  );
}

export default App;
